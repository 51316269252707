import React, { PureComponent } from "react";
import './ColumnStyle.scss';

export default class Column extends PureComponent {

    render() {
        return (
            <div className='column'>
                {this.props.children}
            </div>
        );  
    }
}