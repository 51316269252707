import { PureComponent } from "react";
import { LocalizedContext } from "../LocalizedContext/LocalizedContext";
import InputProps from "./InputProps";
import InputState from "./InputState";
import './InputStyle.scss';

abstract class Input<P = {}> extends PureComponent<InputProps & P, InputState> {
    static contextType = LocalizedContext;
    context! : React.ContextType<typeof LocalizedContext>;

    constructor(props : InputProps & P) {
        super(props);

        this.state = {
            value : props.initialValue || '',
            error : undefined
        }

        this._onChange = this._onChange.bind(this);
        this._getDerivedErrorFromValue = this._getDerivedErrorFromValue.bind(this);
    }
    
    protected _onChange(event : any) : void {
        this.setState({ error : undefined });
        const { value } = event.target;

        const error = this._getDerivedErrorFromValue(value);
        this.setState({ value, error });

        if (!error) {
            this.props.onChange?.(value);
        }
    }

    /**
     * This is where you'll need to validate the control and call `setState` with any errors.
     * @param {string} input The current value of the control.
     */
    protected abstract _getDerivedErrorFromValue(input : string) : string | undefined;
}

export default Input;