import React, { FC, memo, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../Button/Button";
import Column from "../../../Column/Column";
import DialogControlsProps from './DialogControlsProps';

const DialogControls : FC<DialogControlsProps> = props => {
    const { canProceedToNext, backButtonText, nextButtonText, onNextClick } = props;
    const [busy, setBusy] = useState<boolean>(false);

    const history = useHistory();

    const _onBackClick = () => {
        history.goBack();
    }

    const _onNextClick = () => {
        setBusy(true);
        onNextClick(() => setBusy(false));
    }

    return (
        <Column>
            <Button isBusy={busy} variant='stretch' text={nextButtonText ?? 'validate'} onClick={_onNextClick} disabled={!canProceedToNext || busy} />
            <Button isBusy={busy} variant='stretch' theme='inverted' text={backButtonText ?? 'back'} onClick={_onBackClick} disabled={busy}/>
        </Column>
    );
}

export default memo(DialogControls);