import React, { PureComponent } from "react";
import './OverlayStyle.scss';

export default class Overlay extends PureComponent {
    
    render() {
        return (
            <div className='overlay'>
                {this.props.children}
            </div>
        );
    }
}