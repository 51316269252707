import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import ImagePicker from "../../components/Input/components/ImagePicker/ImagePicker";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";

const ConvocationLetterScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onSecondCheckConvocationPictureUpload = (secondCheckConvocation : string) => {
        updateCheck({ secondCheckConvocation });
    }

    const onConfirmClick = () => {
        const { history } = props;
        history.push(Path.COMMENTS);
    }

    return (
        <Dialog>
            <h1>{translate('convocationCopy')}</h1>

            <DialogBody alignment='start'>
                <p>{translate('takeConvocationPicture')}</p>

                <ImagePicker 
                    label='convocationPictureLabel' 
                    initialValue={check.secondCheckConvocation}
                    onChange={onSecondCheckConvocationPictureUpload} />
            </DialogBody>

            <DialogControls 
                canProceedToNext={check.secondCheckConvocation !== undefined}
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(ConvocationLetterScreen);