import React, { memo } from 'react';
import Path from '../../../models/constants/Path';
import { Screen } from '../../../models/types/Check';
import { useCheck } from '../../components/CheckContext/CheckContext';
import DialogBody from '../../components/Dialog/components/DialogBody/DialogBody';
import DialogControls from '../../components/Dialog/components/DialogControls/DialogControls';
import Dialog from '../../components/Dialog/Dialog';
import {  useLocalized } from '../../components/LocalizedContext/LocalizedContext';
import Options from '../../components/Options/Options';

const ExclusionCheckScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();    

    const onWorkerWhitelistedChange = (isWorkerWhitelisted: boolean) => {
		updateCheck({ isWorkerWhitelisted });
	};

    const onConfirmClick = () => {
        const { history } = props;
        history.push(Path.COMMENTS);
    }

    return (
        <Dialog>
            <h1>{translate('exclusionCheckTitle')}</h1>

            <DialogBody alignment='start'> 
                
                <Options
					label="exclusionCheckBody"
					onChange={onWorkerWhitelistedChange}
					selectedValue={check.isWorkerWhitelisted}
					options={[
						{ value: true, text: "yes" },
						{ value: false, text: "no" },
					]}
				/>
            </DialogBody>

            <DialogControls canProceedToNext={true} onNextClick={onConfirmClick} />
        </Dialog>
    );
}   

export default memo(ExclusionCheckScreen);