import {
  TimePicker as MaterialTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { format, isValid, parse } from "date-fns";
import React from "react";
import Input from "../../Input";
import Label from "../Label/Label";
import TimePickerProps from "./TimePickerProps";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default class TimePicker extends Input<TimePickerProps> {
  static defaultTimeMask = 'kk:mm';

  protected _getDerivedErrorFromValue(time: string) {
    const { min, max } = this.props;

    if (time) {
      if (min) {
        const formattedMin = format(min, TimePicker.defaultTimeMask);
        const result = time?.localeCompare(formattedMin);

        if (result === -1) {
          return 'enteredTimeTooEarly';
        }
      }

      if (max) {
        const formattedMax = format(max, TimePicker.defaultTimeMask);
        const result = time?.localeCompare(formattedMax);

        if (result === 1) {
          return 'enteredTimeTooLate';
        }
      }
    }

    return undefined;
  }

  private _formatDateForInput = (date: Date) => {
    return format(date, TimePicker.defaultTimeMask, {
      locale: this.context.locale,
    });
  };

  private onInputChange = (
    date: Date | null,
    value: string | null | undefined
  ) => {
    if (date && isValid(date)) {
      this._onChange({
        target: {
          value: this._formatDateForInput(date),
        },
      });
    } else if (value && value.length === 5) {
      this._onChange({
        target: { value },
      });
    } else {
      this._onChange({
        target: { value: undefined },
      });
    }
  };

  render() {
    const { label, disabled } = this.props;
    const { value, error } = this.state;
    const shownValue = value
      ? parse(value, TimePicker.defaultTimeMask, new Date(), {
          locale: this.context.locale,
        })
      : null;

    return (
      <LocalizationProvider
        adapterLocale={this.context.locale}
        dateAdapter={AdapterDateFns}
      >
        {label && <Label text={label} />}

        <MaterialTimePicker
          ampm={false}
          value={shownValue}
          disabled={disabled}
          onChange={(e, v) => this.onInputChange(e, v)}
          renderInput={(props) => (
            <TextField
              lang={this.context.language}
              placeholder='hh:mm'
              variant="standard"
              error={!!error}
              {...props}
            />
          )}
        />

        <p className='error'>{error && this.context.translate(error!)}</p>
      </LocalizationProvider>
    );
  }
}
