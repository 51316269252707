import React, { useState, useEffect, ChangeEvent } from "react";
import TranslationView from "../../../TranslationView/TranslationView";
import "./ImagePickerStyle.scss";
import heic2any from "heic2any";
import {
  getFileExtensionFromBase64,
  toBase64,
} from "../../../../utils/fileHelper";
import { InputPickerProps } from "./props";

const ImagePicker = (props: InputPickerProps) => {
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [previewImage, setPreviewImage] = useState<string>();

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const picture = event.target.files?.[0];

    if (!picture) {
      return;
    }

    if (picture.name.toLowerCase().endsWith(".heic")) {
      const encodedFile = await toBase64(picture);

      setValue(encodedFile);
      setError(getDerivedErrorFromValue(value));

      if (!error) {
        props.onChange?.(encodedFile);
      }
    } else {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(picture);

      fileReader.onloadend = (event) => {
        if (!fileReader.result) {
          return;
        }

        const value = fileReader.result.toString();
        const error = getDerivedErrorFromValue(value);

        setValue(value);
        setError(error);

        if (!error) {
          props.onChange?.(value);
        }
      };
    }
  };

  const getDerivedErrorFromValue = (path: string) => {
    return undefined;
  };

  const setHEICPreview = async (encodedFile: string) => {
    // Retrieve the encoded file asynchronously
    const response = await fetch(encodedFile);
    const blob = await response.blob();
    const jpegBuffer = await heic2any({
      blob: new Blob([blob], { type: "image/heic" }),
      toType: "image/jpeg",
    });

    //Converts HEIC (High-Efficiency Image Format) files to a jpeg image format
    const convertedBlob = Array.isArray(jpegBuffer)
      ? jpegBuffer[0]
      : jpegBuffer;

    return URL.createObjectURL(convertedBlob);
  };

  useEffect(() => {
    const fetchPreviewImage = async () => {
      if (getFileExtensionFromBase64(value) === "heic") {
        const imageURL = await setHEICPreview(value);
        setPreviewImage(imageURL);
      } else {
        setPreviewImage(value);
      }
    };

    fetchPreviewImage();
  }, [value]);

  return (
    <div className="line">
      <label htmlFor="image-picker">
        {props.label && <TranslationView>{props.label}</TranslationView>}

        <div className="picture-file">
          {previewImage && (
            <img className="preview-file" src={previewImage} alt="preview" />
          )}
        </div>
      </label>

      <input
        id="image-picker"
        type="file"
        onChange={onChange}
        accept="image/*,.jpg,.jpeg,.png,.heic"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default ImagePicker;
