import { nlBE } from "date-fns/locale";
import { createContext, useContext } from "react";

interface LocalizedContextValue {
    language: string;
    locale: Locale;
    changeLanguage: (_: string) => void;
    translate: (_: string) => string;
}

export const LocalizedContext = createContext<LocalizedContextValue>({
    language: 'en',
    locale: nlBE,
    changeLanguage: (_: string) => { },
    translate: (_: string) => 'PLACEHOLDER_TRANSLATION'
});

export function useLocalized() {
    return useContext(LocalizedContext);
}