import React, { memo, useState } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import TextArea from "../../components/Input/components/TextArea/TextArea";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

const CommentScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();
    const [showMedicheckComment, setShowMedicheckComment] = useState<boolean>(false);

    const onPatientCommentChange = (resultCommentWorker : string) => {
        updateCheck({ resultCommentWorker });
    }

    const onMedicheckCommentChange = (resultCommentMedicheck : string) => {
        updateCheck({ resultCommentMedicheck });
    }

    const onConfirmClick = () => {
        const { history } = props;
        history.push(Path.SUMMARY);
    }

    return (
        <Dialog>
            <h1>{translate('comments')}</h1>

            <DialogBody alignment='start'>
                {(check.didShowUp() && check.certificateType !== undefined) &&
                    <TextArea
                        label='leavePatientComment'
                        placeholder='patientCommentPlaceholder'
                        initialValue={check.resultCommentWorker}
                        onChange={onPatientCommentChange} />
                }
                <Options
                    label="showMedicheckCommentLabel"
                    onChange={setShowMedicheckComment}
                    selectedValue={showMedicheckComment}
                    options={[
                        { value: true, text: "yes" },
                        { value: false, text: "no" }
                    ]} />
                {showMedicheckComment &&
                    <TextArea 
                        label='leaveMedicheckComment' 
                        placeholder='medicheckCommentPlaceholder' 
                        initialValue={check.resultCommentMedicheck}
                        onChange={onMedicheckCommentChange} />
                }
            </DialogBody>

            <DialogControls
                canProceedToNext
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(CommentScreen);