import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import TextArea from "../../components/Input/components/TextArea/TextArea";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";

const InitialInjuryInformationScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onInitialInjuryDescriptionChange = (initialInjuryDescription: string) => {
        updateCheck({ initialInjuryDescription });
    }

    const onConfirmClick = () => {
        const { history } = props;
        history.push(Path.RESULT);
    }

    return (
        <Dialog>
            <h1>{translate("injuryDescription")}</h1>

            <DialogBody alignment='start'>
                <TextArea
                    maxLength={57}
                    label="initialInjuryDescriptionLabel"
                    placeholder="injuryDescription"
                    initialValue={check.initialInjuryDescription}
                    onChange={onInitialInjuryDescriptionChange} />
            </DialogBody>

            <DialogControls
                canProceedToNext={check.initialInjuryDescription !== ""}
                onNextClick={onConfirmClick} />
        </Dialog>
    )
}

export default memo(InitialInjuryInformationScreen);