import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import Button from "../../components/Button/Button";
import { useCheck } from "../../components/CheckContext/CheckContext";
import Dialog from "../../components/Dialog/Dialog";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";

const CertificatePossessionScreen: Screen = props => {
    const { translate } = useLocalized();
    const { updateCheck } = useCheck();

    const onCertificatePossessionClick = (hasCertificate: boolean) => {
        const { history } = props;

        if (hasCertificate) {
            history.push(Path.CERTIFICATE_INFORMATION);
        }

        else {
            updateCheck({ certificateType: -1 });
            history.push(Path.NO_CERTIFICATE);
        }
    }

    return (
        <Dialog>
            <h1>{translate("certificatePossession")}</h1>

            <p>{translate("doesWorkerHaveCertificate")}</p>

            <div className="buttons">
                <Button text="no" onClick={() => onCertificatePossessionClick(false)} />

                <Button text="yes" onClick={() => onCertificatePossessionClick(true)} />
            </div>
        </Dialog>
    )
}

export default memo(CertificatePossessionScreen);