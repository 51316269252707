import { format } from 'date-fns';
import React, { Fragment, useEffect } from 'react';
import CheckLocation from '../../../models/constants/CheckLocation';
import Path from '../../../models/constants/Path';
import Check, { ICheck, Screen } from '../../../models/types/Check';
import API from '../../../network/ API';
import Button from '../../components/Button/Button';
import { useCheck } from '../../components/CheckContext/CheckContext';
import Dialog from '../../components/Dialog/Dialog';
import { useLocalized } from '../../components/LocalizedContext/LocalizedContext';
import TranslationView from '../../components/TranslationView/TranslationView';
import './LandingScreen.scss';
import LandingScreenProps from './LandingScreenProps';

const LandingScreen: Screen<LandingScreenProps> = (props) => {
	const { history, match } = props;

	const { check, updateCheck } = useCheck();
	const { changeLanguage, translate } = useLocalized();

	useEffect(() => {
		loadCheck();
		// eslint-disable-next-line
	}, []);

	async function loadCheck() {
		const { id } = match.params;
		const response = await API.getCheckInfo(id);

		if (!response?.ok) {
			return;
		}

		const json: ICheck = await response.json();

		json.convocationShouldBeReported =
			json.location === CheckLocation.HOME && json.secondCheckNeeded === false;

		// This is to ensure that this field which is not nullable on the BE
		// will not have a preset value on the form as the BE initialises it to `false`
		json.incapacityValidatedToday = undefined;

		if (json.checkDate) {
			json.checkDate = Check.formatDate(json.checkDate);
		}

		if (json.secondCheckDate) {
			json.secondCheckDate = Check.formatDate(json.secondCheckDate);
		}

		if (json.incapacityStartDate) {
			json.incapacityStartDate = Check.formatDate(json.incapacityStartDate);
		}

		if (json.incapacityEndDate) {
			json.incapacityEndDate = Check.formatDate(json.incapacityEndDate);
		}

		// Change the language of the app to that of the doctor
		changeLanguage(json.doctorMainLanguage.toLowerCase());
		updateCheck(json);
	}

	const onPatientPresenceClick = (present: boolean) => {
		updateCheck({ secondCheckNeeded: !present, didWorkerShowUp: present });

		if (present) {
			history.push(Path.INDEPENDENCE);
		} else if (check.isAtOffice()) {
			history.push(Path.COMMENTS);
		} else {
			history.push(Path.CHECK_RESCHEDULING);
		}
	};

	return (
		<Dialog>
			{!check.id ? (
				<h1>Loading...</h1>
			) : (
				<Fragment>
					<h1>{translate('patientPresence')}</h1>

					<p>
						{translate('helloDoctor')}
						<strong>{check.doctorFullName()}</strong>
						&#44;
					</p>

					<p>
						{translate('confirmCheckResult')}
						<strong>{check.workerFullName()}</strong>
						{translate('incapacityPeriodStarting')}
						<strong>{check.secondCheckDate ?? check.checkDate}</strong>
						<span>.</span>
					</p>

					<TranslationView>
						{check.isAtOffice() ? 'wasPatientAtOffice' : 'wasPatientHome'}
					</TranslationView>

					<div className='buttons'>
						<Button text='no' onClick={() => onPatientPresenceClick(false)} />
						<Button text='yes' onClick={() => onPatientPresenceClick(true)} />
					</div>
				</Fragment>
			)}
		</Dialog>
	);
};

export default LandingScreen;
