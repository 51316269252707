import React, { createRef, Fragment, memo, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Path from '../../../models/constants/Path';
import { Screen } from '../../../models/types/Check';
import Button from '../../components/Button/Button';
import { useCheck } from '../../components/CheckContext/CheckContext';
import DialogBody from '../../components/Dialog/components/DialogBody/DialogBody';
import Dialog from '../../components/Dialog/Dialog';
import { LocalizedContext, useLocalized } from '../../components/LocalizedContext/LocalizedContext';
import { showOverlay } from '../../components/OverlayWrapper/OverlayWrapper';
import './PatientSignatureScreenStyle.scss';

const PatientSignatureScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();
    
    const canvas = createRef<typeof SignatureCanvas>();

    useEffect(() => {
        // This prefills the canvas with the previous signature and 
        // prevents the popup from being shown every time.
        if (canvas.current && check.resultWorkerSignature) {
            canvas.current.fromDataURL(check.resultWorkerSignature);
            return;
        }

        const remove = showOverlay(() => (
            <LocalizedContext.Consumer>
                {({ translate }) => (
                    <Fragment>
                        <p>
                            {translate('theSignatureOf')}
                            <strong>{check.workerFullName()}</strong>
                            {translate('isRequired')}
                        </p>

                        <p>{translate('signatureInfo')}</p>

                        <Button variant='stretch' text='ok' onClick={remove} />
                    </Fragment>
                )}
            </LocalizedContext.Consumer>
        ));
        // eslint-disable-next-line
    }, []);

    const onEraseClick = () => {
        if (!canvas.current) {
            return;
        }

        canvas.current.clear();
        updateCheck({ resultWorkerSignature : undefined });
    }

    const onSignatureCompleted = () => {
        if (!canvas.current) {
            return;
        }

        updateCheck({
            resultWorkerSignature : canvas.current.toDataURL()
        });
    }

    const onConfirmClick = () => {
        const { history } = props;
        if(check.hasCompanyWhitelisting){
            history.push(Path.EXCLUSION_CHECK);
        }else {
            history.push(Path.COMMENTS);
        }
    }

    return (
        <Dialog>
            <h1>{translate('patientSignature')}</h1>

            <DialogBody alignment='start'> 
                <label>{translate('signatureOf') + check.workerFullName()}</label>
                
                <SignatureCanvas ref={canvas} onEnd={onSignatureCompleted} />
            </DialogBody>

            <div className='buttons'>
                <Button 
                    text='erase' 
                    onClick={onEraseClick} />

                <Button 
                    text='validate' 
                    disabled={check.resultWorkerSignature === undefined} 
                    onClick={onConfirmClick} />
            </div>
        </Dialog>
    );
}   

export default memo(PatientSignatureScreen);