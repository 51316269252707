import React, { PureComponent } from "react";
import './Dialog.scss';

export default class Dialog extends PureComponent {
    
    render() {
        return (
            <div id='dialog-wrapper'>
                <div id='dialog-inner'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}