import React, { Children, PureComponent } from "react";
import { LocalizedContext } from "../LocalizedContext/LocalizedContext";
import TranslationViewProps from "./TranslationViewProps";

export default class TranslationView extends PureComponent<TranslationViewProps> {
    
    render() {
        const { inline, onClick, children } = this.props;

        return (
            <LocalizedContext.Consumer>
                {({ language, changeLanguage, translate }) => {                    
                    return inline ? (
                        <span onClick={onClick}>
                            {Children.map(children, child => {
                                if (typeof child === "string") {
                                    return translate(child);
                                }

                                return child;
                            })}
                        </span>
                    ) : (
                        <p onClick={onClick}>
                            {Children.map(children, child => {
                                if (typeof child === "string") {
                                    return translate(child);
                                }

                                return child;
                            })}
                        </p>
                    )
                }}
            </LocalizedContext.Consumer>
        );
    }
}