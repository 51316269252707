import React, { Component } from "react";
import TranslationView from "../../../TranslationView/TranslationView";
import LabelProps from "./LabelProps";
import "./LabelStyle.scss";

export default class Label extends Component<LabelProps> {
    
    render() {
        return (
            <label>
                <TranslationView inline>
                    {this.props.text}
                </TranslationView>
            </label>
        );
    }
}