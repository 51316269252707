import { enGB, fr, nl } from "date-fns/locale";
import React, { Component } from "react";
import { LocalizedContext } from "./LocalizedContext";
import LocalizedContextProviderProps from "./LocalizedContextProviderProps";
import LocalizedContextProviderState from "./LocalizedContextProviderState";

export default class LocalizedContextProvider extends Component<LocalizedContextProviderProps, LocalizedContextProviderState> {
    
    state = {
        language : this.props.initialLanguage,
        locale : this._getNewLocale(this.props.initialLanguage)
    }

    constructor(props : LocalizedContextProviderProps) {
        super(props);

        this._changeLanguage = this._changeLanguage.bind(this);
        this._getNewLocale = this._getNewLocale.bind(this);
        this._translate = this._translate.bind(this);
    }

    private _changeLanguage(language : string) {
        if (!this.props.supportedLanguages.includes(language)) {
            throw new Error(`Unsupported language: ${language}`);
        }

        this.setState({ language, locale : this._getNewLocale(language) });
    }

    private _getNewLocale(language : string) {
        switch (language) {
            case "fr":
                return fr;

            case "nl":
                return nl;   

            case "en":
            default:
                return enGB;
        }
    }

    private _translate(key : string) : string {
        const { translations } = this.props;
        const { language } = this.state;
        
        try {
            return (translations as any)[key][language];
        } catch (error) {
            return `[${key}]`;
        }
    }

    private _computeValue = () => {
        return {
            language : this.state.language,
            locale : this.state.locale,
            changeLanguage : this._changeLanguage,
            translate : this._translate
        }
    }

    render() {        
        return (
            <LocalizedContext.Provider value={this._computeValue()}>
                {this.props.children}
            </LocalizedContext.Provider>
        );
    }
}