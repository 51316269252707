
enum Path {
    INDEPENDENCE = "/independence",
    CERTIFICATE_POSSESSION = "/certificate-possession",
    CERTIFICATE_INFORMATION = "/certificate-information",
    ACCIDENT_INFORMATION = "/accident-information",
    INJURY_INFORMATION = "/injury-information",
    INITIAL_INJURY_INFORMATION = "/initial-injury-information",
    CHECK_RESCHEDULING = "/check-rescheduling",
    CONVOCATION_LETTER = "/convocation-letter",
    COMMENTS = "/comments",
    PATIENT_SIGNATURE = "/patient-signature",
    EXCLUSION_CHECK = "/exclusion-check",
    RESULT = "/result",
    NO_CERTIFICATE = "/no-certificate",
    EARLY_RETURN = "/early-return",
    INCAPACITY_CONFIRMATION = "/incapacity-confirmation",
    SUMMARY = "/summary",
    THANKS = "/thanks"
}

export default Path;