import React, { PureComponent } from "react";
import Lottie, { Options } from "react-lottie";
import TranslationView from "../TranslationView/TranslationView";
import ButtonProps from './ButtonProps';
import './ButtonStyle.scss';

export default class Button extends PureComponent<ButtonProps> {

    static defaultOptions : Options = {
        loop: true,
        autoplay: true, 
        animationData: require('./assets/loader.json'),
        rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
        }
    }

    render() {
        const { disabled, theme, variant, text, onClick, isBusy } = this.props;

        return(
            <button className={`btn ${variant} ${theme}`} type='button' disabled={disabled} onClick={onClick}>
                {
                    isBusy ? (
                        <div className='btn-content'>
                            <Lottie options={Button.defaultOptions} height={100} width={100} />
                        </div>    
                    ) : (
                        <TranslationView inline>
                            {text}
                        </TranslationView>
                    )
                }
            </button>
        );
    }
}