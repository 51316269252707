import React, { memo } from "react";
import CheckResult from "../../../models/constants/CheckResult";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

const ReturnDateResultScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onCheckResultChange = (result : CheckResult) => {
        if (result === CheckResult.CONFIRMED) {
            updateCheck({ result, newIncapacityEndDate : check.incapacityEndDate });
        }

        else {
            updateCheck({ result });
        }
    }

    const onConfirmClick = () => {
        const { history } = props;

        if (check.result === CheckResult.EARLY) {
            history.push(Path.EARLY_RETURN);
        }

        else {
            history.push(Path.INCAPACITY_CONFIRMATION);
        }
    }

    return (
        <Dialog>
            <h1>{translate("checkResult")}</h1>

            <DialogBody alignment='start'>
                <Options 
                    horizontal
                    label="checkResultLabel"
                    selectedValue={check.result}
                    onChange={onCheckResultChange}
                    options={[
                        { value : CheckResult.EARLY, text : "earlyReturn" },
                        { value : CheckResult.CONFIRMED, text : "returnConfirmed" }
                    ]} />
            </DialogBody>

            <DialogControls
                canProceedToNext={check.result !== undefined}
                onNextClick={onConfirmClick} />
        </Dialog>        
    )
}

export default memo(ReturnDateResultScreen);