import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";

const IndependenceScreen : Screen = props => {
    const { check } = useCheck();
    const { translate } = useLocalized();

    const onIndependenceValidated = () => {
        const { history } = props;

        if (check.isInNoCertificatePeriod()) {
            history.push(Path.CERTIFICATE_POSSESSION);
        }

        else {
            history.push(Path.CERTIFICATE_INFORMATION);
        }
    }

    return (
        <Dialog>
            <h1>{translate('independenceDeclaration')}</h1>

            <div className='dialog-content'>
                <p>
                    {translate('doctorNameUndersigned')}
                    <strong>{check.doctorFullName()}</strong>
                    {translate('delegateDoctor')}
                    <strong>{check.companyCommercialName}</strong>
                    {translate('toReevaluateIncapacity')}
                    <strong>{check.workerFullName()}</strong>
                    {translate('declareIndependence')}
                </p>
            </div>

            <DialogControls 
                canProceedToNext
                onNextClick={onIndependenceValidated} />
        </Dialog>
    )
}

export default memo(IndependenceScreen);