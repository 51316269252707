import React, { Component } from "react";
import Overlay from "../Overlay/Overlay";
import OverlayWrapperState from "./OverlayWrapperState";

export interface Modal {
    id? : number;
    builder : () => JSX.Element;
}

let _singleton : OverlayWrapper;
export default class OverlayWrapper extends Component<{}, OverlayWrapperState> {
    private static _OVERLAY_COUNTER = 0;

    constructor(props : any)  {
        super(props);

        this.state = {
            modals : []
        }
    }

    componentDidMount() {
        _singleton = this;

        document.addEventListener('keydown', this._onKeyDown)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._onKeyDown);
    }

    private _onKeyDown = (event : KeyboardEvent) => {
        if (this.state.modals.length === 0) {
            return;
        }

        if (event.key === 'Escape') {
            this.removeOverlay(this.state.modals[this.state.modals.length - 1].id!);
        }
    }

    addOverlay = (modal : Modal) => {
        OverlayWrapper._OVERLAY_COUNTER++;

        modal.id = OverlayWrapper._OVERLAY_COUNTER;
        this.setState({
            modals : [...this.state.modals, modal]
        });

        return () => this.removeOverlay(OverlayWrapper._OVERLAY_COUNTER);
    }

    removeOverlay = (overlayId : number) => {
        const { modals } = this.state;
        const index = modals.findIndex(modal => modal.id === overlayId);

        if (index === -1) {
            return;
        }

        modals.splice(index, 1);
        this.setState({ modals });
    }

    render() {
        const { modals } = this.state;

        if (modals.length === 0) {
            return null;
        }

        return (
            <Overlay>
                {modals.map(modal => (
                    <div key={modal.id} className='overlay-content'>
                        {modal.builder()}
                    </div>
                ))}
            </Overlay>
        );
    }
}

export function showOverlay(builder : () => JSX.Element) {
    return _singleton.addOverlay({ builder });
}