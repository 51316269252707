import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import TextArea from "../../components/Input/components/TextArea/TextArea";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

const InjuryInformationScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onInjuryValidationChange = (validate: boolean) => {
        updateCheck({ injuryDifference: !validate });
    }

    const onInjuryDescriptionChange = (injuryDescription: string) => {
        updateCheck({ injuryDescription });
    }

    const onConfirmClick = () => {
        const { history } = props;

        if (check.injuryDifference) {
            history.push(Path.INITIAL_INJURY_INFORMATION);
        }

        else {
            history.push(Path.RESULT);
        }
    }

    return (
        <Dialog>
            <h1>{translate("injuryDescription")}</h1>

            <DialogBody alignment="start">
                <TextArea
                    maxLength={57}
                    label="injuryDescriptionLabel"
                    placeholder="injuryDescription"
                    initialValue={check.injuryDescription}
                    onChange={onInjuryDescriptionChange} />

                <Options
                    label="injuryDescriptionSameAsTreatingDoctorLabel"
                    selectedValue={!check.injuryDifference}
                    onChange={onInjuryValidationChange}
                    options={[
                        { value: true, text: "yes" },
                        { value: false, text: "no" }
                    ]} />
            </DialogBody>

            <DialogControls
                canProceedToNext={check.injuryDescription !== ""}
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(InjuryInformationScreen);