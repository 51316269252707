import React, { PureComponent } from "react";
import Label from "../Input/components/Label/Label";
import Option from "./components/Option/Option";
import OptionsProps from "./OptionsProps";

export default class Options<T> extends PureComponent<OptionsProps<T>> {

    render() {
        const { horizontal, label, selectedValue, options, onChange } = this.props;

        return (
            <div style={{ display : horizontal ? "flex" : undefined, flexDirection : horizontal ? "column" : undefined }}>
                <Label text={label} />

                {options.map((opt, index) => (
                    <Option 
                        key={index.toString()}
                        value={opt.value}
                        text={opt.text}
                        onClick={onChange}
                        selected={selectedValue === opt.value} />
                ))}
            </div>
        );
    }
}