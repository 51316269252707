import React, { Fragment, PureComponent } from "react";
import Button from "../Button/Button";
import { showOverlay } from "../OverlayWrapper/OverlayWrapper";
import TranslationView from "../TranslationView/TranslationView";
import './Footer.scss';

export default class Footer extends PureComponent {
    
    private _onHelpClick = () => {
        const remove = showOverlay(() => (
            <Fragment>
                <TranslationView>
                    helpIntro
                </TranslationView>

                <p>
                    <TranslationView inline>
                        helpMessage
                    </TranslationView>

                    <strong>
                        <TranslationView inline>
                            helpNumber
                        </TranslationView>
                    </strong>
                </p>

                <Button variant='stretch' text='ok' onClick={remove} />
            </Fragment>
        ));
    }

    render() {
        return(
            <footer>
                <TranslationView inline onClick={this._onHelpClick}>
                    needHelpContactUs
                </TranslationView>
            </footer>
        );
    }
}