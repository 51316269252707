import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import Check, { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import DatePicker from "../../components/Input/components/DatePicker/DatePicker";
import TimePicker from "../../components/Input/components/TimePicker/TimePicker";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import { format, parse } from "date-fns";

const EARLIEST_CHECK_HOUR = 7;
const LATEST_CHECK_HOUR = 21;

const CheckReschedulingScreen: Screen = (props) => {
	const { translate } = useLocalized();
	const { check, updateCheck } = useCheck();

	const onSecondCheckDateChange = (secondCheckDate: string) => {
		updateCheck({ secondCheckDate });
	};

	const onSecondCheckTimeChange = (secondCheckTime: string) => {
		updateCheck({ secondCheckTime });
	};

	const getEarliestDateAndTime = () => {
		const rightNow = new Date();
		rightNow.setHours(EARLIEST_CHECK_HOUR, 0);
		rightNow.setDate(rightNow.getDate());
		return rightNow;
	};

	const getLatestDate = () => {
		if (!check.incapacityEndDate) {
			return undefined;
		}

		return Check.parseDate(check.incapacityEndDate);
	};

	const getLatestTime = () => {
		const rightNow = new Date();
		rightNow.setHours(LATEST_CHECK_HOUR, 0);
		rightNow.setDate(rightNow.getDate());
		return rightNow;
	};

	const onConfirmClick = () => {
		const { history } = props;
		history.push(Path.CONVOCATION_LETTER);
	};

	const canProceedToNext = () => {
		if (!check.secondCheckTime || !check.secondCheckDate) {
			return false;
		}
		
		// Combine the secondCheckDate and secondCheckTime
		const combinedDateTimeString = `${check.secondCheckDate} ${check.secondCheckTime}`;
		
		// Parse the combined string to a Date object
		const combinedDateTime = parse(combinedDateTimeString, 'dd/MM/yyyy HH:mm', new Date());
		
		// Check if the combined date and time is smaller than the current date and time
		if (combinedDateTime < min) {
			return false;
		}

		var latestDate = getLatestDate();
		if(!latestDate || latestDate < Check.parseDate(check.secondCheckDate)){
			return false;
		}

		return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g.test(check.secondCheckTime);
	};

	const min = getEarliestDateAndTime();
	return (
		<Dialog>
			<h1>{translate("convocationInCabinet")}</h1>

			<DialogBody alignment="start">
				<p>
					{translate("mustBeInCabinet")}
					<strong>{translate("convocationLetter")}</strong>
					{translate("inMailbox")}
				</p>

				<DatePicker label="secondCheckDateLabel" min={min} max={getLatestDate()} initialValue={check.secondCheckDate} onChange={onSecondCheckDateChange} />

				<TimePicker min={min} max={getLatestTime()} label="secondCheckTimeLabel" onChange={onSecondCheckTimeChange} initialValue={check.secondCheckTime} value={check.secondCheckTime} disabled={check.secondCheckDate === undefined} />
			</DialogBody>

			<DialogControls canProceedToNext={canProceedToNext()} onNextClick={onConfirmClick} />
		</Dialog>
	);
};

export default memo(CheckReschedulingScreen);
