export function toBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function getFileExtensionFromBase64(input: string) {
  var startIndex = input.indexOf("/") + 1;
  var endIndex = input.indexOf(";");
  if (startIndex === -1 || endIndex === -1) return "";

  var extension = input.substring(startIndex, endIndex);
  return extension;
}
