import React, { memo } from "react";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";
import Certificate from "../../../models/constants/Certificate";

const NoCertificateScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onIncapacityValidationChange = (incapacityValidatedToday: boolean) => {
        updateCheck({ incapacityValidatedToday });
    }

    const onConfirmClick = () => {
        const { history } = props;

        updateCheck({ certificateType: Certificate.UNKNOWN });

        if (check.workerPhoneNumber) {
            if(check.hasCompanyWhitelisting){
                history.push(Path.EXCLUSION_CHECK);
            }else {
                history.push(Path.COMMENTS);
            }
        }

        else {
            history.push(Path.PATIENT_SIGNATURE);
        }
    }

    return (
        <Dialog>
            <h1>{translate("noCertificateYet")}</h1>

            <DialogBody alignment='start'>
                <p>
                    {translate("seeingAs")}
                    <strong>{check.workerFullName()}</strong>
                    {translate("hasNotYetSeenTreatingDoctor")}
                    <strong>{check.incapacityStartDate}</strong>
                    <span>.</span>
                </p>

                <Options
                    label="confirmTodayIncapacityLabel"
                    onChange={onIncapacityValidationChange}
                    selectedValue={check.incapacityValidatedToday}
                    options={[
                        { value: true, text: "yes" },
                        { value: false, text: "no" }
                    ]} />
            </DialogBody>

            <DialogControls
                canProceedToNext={check.incapacityValidatedToday !== undefined}
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(NoCertificateScreen);