import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import './NotFoundScreen.scss';

export default class NotFoundScreen extends Component<RouteComponentProps> {

    render() {
        return (
            <h1>Not Found</h1>
        );
    }
}