import React, { PureComponent } from "react";
import TranslationView from "../../../TranslationView/TranslationView";
import OptionProps from "./OptionProps";
import "./OptionStyle.scss";

export default class Option<T> extends PureComponent<OptionProps<T>> {

    render() {
        const { selected, value, text, onClick } = this.props;

        return (
            <button className={`option ${selected && "selected"}`} type="button" onClick={() => onClick(value)}>
                <TranslationView inline>
                    {text}
                </TranslationView>
            </button>
        );
    }
}