import React from "react";
import { LocalizedContext } from "../../../LocalizedContext/LocalizedContext";
import TranslationView from "../../../TranslationView/TranslationView";
import Input from "../../Input";
import Label from "../Label/Label";
import './TextAreaStyle.scss';

export default class TextArea extends Input {

    _getDerivedErrorFromValue(value: string): string | undefined {
        return undefined;
    }

    _onChange(event: any) {
        const { label, placeholder, maxLength } = this.props;

        this.setState({ error: undefined });
        const { value } = event.target;

        if (value) {
            if (maxLength) {
                if (value.length > maxLength) {
                    return false;
                }
            }
        }

        const error = this._getDerivedErrorFromValue(value);
        this.setState({ value, error });

        if (!error) {
            this.props.onChange?.(value);
        }
    }

    render() {
        const { label, placeholder, maxLength } = this.props;
        const { value, error } = this.state;

        return (
            <LocalizedContext.Consumer>
                {({ translate }) => (
                    <div className='medicheck-input'>
                        <div className={error ? 'error' : 'line'}>
                            {label &&
                                <Label text={label} />
                            }

                            <textarea maxLength={maxLength} value={value} placeholder={placeholder && translate(placeholder)} onChange={this._onChange} />

                            {
                                maxLength &&
                                <div style={{ width: '100%', textAlign: 'right', fontSize: 14, color: '#405364', fontWeight: 300 }}>
                                    {`${value.length}/${maxLength}`}
                                </div>
                            }


                            {error &&
                                <TranslationView>
                                    {error}
                                </TranslationView>
                            }
                        </div>
                    </div>
                )}
            </LocalizedContext.Consumer>
        );
    }
}