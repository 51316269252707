import { Config } from '../config/config';
import { ICheck } from '../models/types/Check';

export default class API {

    static BASE_URL = `${Config.api.url}/v1/api/checks`
    // static BASE_URL = 'https://medicheck-backend.azurewebsites.net/v1/api/checks';
    // static BASE_URL = 'https://medicheck-backend-staging.azurewebsites.net/v1/api/checks';
    // static BASE_URL = 'https://localhost:5001/v1/api/checks';

    static async getCheckInfo(checkId: string): Promise<Response | undefined> {
        try {
            const response = await fetch(API.BASE_URL + `/${checkId}/results`);
            return response;
        } catch (error) {
            return undefined;
        }
    }

    static async sendCheckResult(check: ICheck): Promise<Response | undefined> {
        try {
            const response = await fetch(API.BASE_URL + `/${check.id}/results`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(check)
            });

            return response;
        } catch (error) {
            return undefined;
        }
    }
}