import { compareAsc } from 'date-fns';
import React, { memo } from 'react';
import Path from '../../../models/constants/Path';
import Check, { Screen } from '../../../models/types/Check';
import { useCheck } from '../../components/CheckContext/CheckContext';
import DialogBody from '../../components/Dialog/components/DialogBody/DialogBody';
import DialogControls from '../../components/Dialog/components/DialogControls/DialogControls';
import Dialog from '../../components/Dialog/Dialog';
import { useLocalized } from '../../components/LocalizedContext/LocalizedContext';
import TranslationView from '../../components/TranslationView/TranslationView';

const SummaryScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check, sendResults } = useCheck();
    
    const getFirstSentenceForCheckResult = () => {
        if (check.didShowUp()) {
            return (
                <TranslationView>
                    {check.isAtOffice() ? "patientDidShowUp" : "patientWasHome"}
                </TranslationView>
            );
        }

        else {  
            return (
                <TranslationView>
                    {check.isAtOffice() ? "patientDidNotShowUp" : "patientWasNotHome"}
                </TranslationView>
            );
        }
    }

    const getSecondSentenceForCheckResult = () => {
        if (!check.incapacityEndDate || !check.newIncapacityEndDate) {
            // TODO: Missing sentence in the document?
            return null;
        }

        else if (check.incapacityEndDate === check.newIncapacityEndDate) {
            return (
                <TranslationView>
                    dateNotChanged
                    <strong>{check.workerFullName()}</strong>
                    dateNotChangedUntil
                    <strong>{check.incapacityEndDate}</strong>
                    <span>.</span>
                </TranslationView>
            );
        }

        else if (
            compareAsc(
                Check.parseDate(check.incapacityEndDate),
                Check.parseDate(check.newIncapacityEndDate)
            ) === 1
        ) {
            return (
                <TranslationView>
                    dateChanged
                    <strong>{check.workerFullName()}</strong>
                    dateChangedStarting
                    <strong>{check.newIncapacityEndDate}</strong>
                    <span>.</span>
                </TranslationView>
            );
        }

        return null;
    }

    const onConfirmClick = async (done : () => void) => {
        const { history } = props;

        const response = await sendResults();
        done();

        if (!response?.ok) {
            return;
        }

        history.push(Path.THANKS);
    }

    return (
        <Dialog>
            <h1>{translate('summary')}</h1>

            <DialogBody alignment='start'>
                <p>
                    {translate('checkSummaryFor')}
                    <strong>{check.workerFullName()}</strong>
                    {translate('isTheFollowing')}
                </p>

                {getFirstSentenceForCheckResult()}

                {getSecondSentenceForCheckResult()}
            </DialogBody>
            
            <DialogControls 
                canProceedToNext
                nextButtonText='confirmAndSendToMedicheck'
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(SummaryScreen);