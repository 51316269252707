import React, { PureComponent } from "react";
import './DialogBody.scss';
import DialogBodyProps from "./DialogBodyProps";

export default class DialogBody extends PureComponent<DialogBodyProps> {
    
    render() {
        const { alignment } = this.props;

        return (
            <div className={`dialog-content ${alignment}`}>
                {this.props.children}
            </div>
        );
    }
}