import React, { PureComponent } from "react";
import './Header.scss';

export default class Header extends PureComponent {

    render() {
        return (
            <header>
                <img 
                    id='medicheck-header-logo' 
                    src={require('../../assets/medicheck_logo.png')} 
                    alt="Medicheck Logo" />
            </header>
        );
    }
}