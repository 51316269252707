import React, { Fragment, memo } from "react";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import Dialog from "../../components/Dialog/Dialog";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import TranslationView from "../../components/TranslationView/TranslationView";

const CompletionScreen : Screen = props => {
    const { translate } = useLocalized();
    const { check } = useCheck();

    return (
        <Dialog>
            <h1>{translate('sentConfirmation')}</h1>

            <DialogBody>
                {(check.isAtHome() && !check.didShowUp()) ? (
                    <Fragment>
                        <TranslationView>
                            rescheduleComplete
                        </TranslationView>

                        <TranslationView>
                            willReceiveEmailForNextCheck
                        </TranslationView>
                    </Fragment>
                ) : (
                    <TranslationView>
                        dataConfirmedReceiveCopy
                    </TranslationView>
                )}
            </DialogBody>
        </Dialog>
    );
}

export default memo(CompletionScreen);