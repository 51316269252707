import React, { memo } from "react";
import Answer from "../../../models/constants/Answer";
import Path from "../../../models/constants/Path";
import { Screen } from "../../../models/types/Check";
import { useCheck } from "../../components/CheckContext/CheckContext";
import DialogBody from "../../components/Dialog/components/DialogBody/DialogBody";
import DialogControls from "../../components/Dialog/components/DialogControls/DialogControls";
import Dialog from "../../components/Dialog/Dialog";
import { useLocalized } from "../../components/LocalizedContext/LocalizedContext";
import Options from "../../components/Options/Options";

const IncapacityConfirmationScreen: Screen = props => {
    const { translate } = useLocalized();
    const { check, updateCheck } = useCheck();

    const onPlausibilityChange = (plausibility: Answer) => {
        updateCheck({ plausibility });
    }

    const onConfirmClick = () => {
        const { history } = props;

        if (check.workerPhoneNumber) {
            if(check.hasCompanyWhitelisting){
                history.push(Path.EXCLUSION_CHECK);
            }else {
                history.push(Path.COMMENTS);
            }
        }

        else {
            history.push(Path.PATIENT_SIGNATURE);
        }
    }

    return (
        <Dialog>
            <h1>{translate("incapacityConfirmation")}</h1>

            <DialogBody alignment="start">
                <Options
                    label="plausibilityLabel"
                    selectedValue={check.plausibility}
                    onChange={onPlausibilityChange}
                    options={[
                        { value: Answer.NO, text: "excluded" },
                        { value: Answer.YES, text: "probable" },
                        { value: Answer.UNSURE, text: "possible" }
                    ]} />
            </DialogBody>

            <DialogControls
                canProceedToNext={check.plausibility !== undefined}
                onNextClick={onConfirmClick} />
        </Dialog>
    );
}

export default memo(IncapacityConfirmationScreen);