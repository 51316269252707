import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/es6/set';
import 'core-js/es6/map';
// import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './presentation/App';

ReactDOM.render(<App />, document.getElementById('root'));